import BaseBean from "@/utils/BaseBean";

interface IImageSource{
    selImageName:string
    selImagePath:string
    arrayPath: Array<any>
}
export interface ILockScreenDataObj {
    utilInst:LockScreenUtil
    refMap:Map<string,any>
    userInfo:IStoreUserInfo
    backMusic: string
    picDialogVisible:boolean
    imageSource:IImageSource
    backgroundImage:string
    password:string
    marginTop:number
    time:string
    defaultPic:string
}
export default class LockScreenUtil extends BaseBean{
    public dataObj:ILockScreenDataObj;
    constructor(proxy:any,dataObj:ILockScreenDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

    public configPoiAndShowTime():void{

        let height=window.innerHeight;
        this.dataObj.marginTop=(height-400)/2-200;
        let that=this;

        setInterval(()=>{
            let a = new Date();
            let b = a.toLocaleTimeString();
            that.dataObj.time=b;
        },1000);
    }

    public configBgAndInitImageSources():void{

        let bgImag=localStorage.getItem('bgImag');

        let _imageSource=sessionStorage.getItem("imageSource") as string;
        let imageSource= JSON.parse(this.utils.UtilPub.decrypt(_imageSource)) as Array<any>;
        this.dataObj.imageSource.arrayPath=imageSource;

        if(bgImag==null && this.dataObj.imageSource.arrayPath.length!=0)
            this.dataObj.backgroundImage='url('+this.dataObj.imageSource.arrayPath[0].path+')';
        if(bgImag!=null)this.dataObj.backgroundImage='url('+bgImag+')';
    }

    public musicEvent():void{
        if((this.dataObj.refMap.get('musicPlayRef').paused)){
            this.dataObj.refMap.get('musicPlayRef').play();
        }else{
            this.dataObj.refMap.get('musicPlayRef').pause();
        }
    }

    public async lockLogin():Promise<void>{
        if(this.utils.UtilPub.isEmpty(this.dataObj.password)){
            this.proxy.$message('请输入密码');
        }else{
            let usercode=this.dataObj.userInfo.usercode;
            this.dataObj.password = this.utils.UtilPub.encrypt(this.dataObj.password);

            let params= {operateType:'unLockScreen',usercode:usercode,password:this.dataObj.password}
            let res = await this.utils.Api.postRequest({ url: this.utils.Api.buildUrl("/user/custom"), params: params});

            this.dataObj.password='';
            if(res.result && res.unLockScreenResult){//解锁成功，重新设置lockScreen对象到sessionStorage中
                let lockScreen=JSON.parse(sessionStorage.getItem("lockScreen")as string) as ILockScreen;
                lockScreen.lockScreen=false;
                sessionStorage.setItem("lockScreen",JSON.stringify(lockScreen));
                await this.proxy.$router.replace('/');
            }else{
                if(!res.result)this.proxy.$message(res.msg);
                if(!res.unLockScreenResult && res.unLockScreenMsg)this.proxy.$message(res.unLockScreenMsg);
            }
        }
    }

    public async lockLoginOut():Promise<void>{
        this.proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});

        sessionStorage.removeItem("userInfo");
        let res = await this.utils.Api.loginOut();
        if(res.result){
            this.utils.Tools.success({message: "成功退出登录!"});

            location.reload();
        }else{
            this.utils.Tools.error();
        }
    }

    public imgClick(item:any,event:any):void{

        this.dataObj.imageSource.selImageName=item.preName;
        this.dataObj.imageSource.selImagePath=item.path;
    }

    public picDialogSure():void{
        this.dataObj.picDialogVisible=false;
        this.dataObj.backgroundImage='url('+this.dataObj.imageSource.selImagePath+')';

        localStorage.setItem('bgImag',this.dataObj.imageSource.selImagePath);
    }
}