import {reactive,toRefs,onBeforeMount,onMounted,computed,getCurrentInstance,defineComponent,nextTick} from 'vue';
import LockScreenUtil,{ILockScreenDataObj} from "@/views/sysviews/yunwei/lockScreen/lockScreenUtil";
import music1 from '../../../../assets/music/music1.mp3';
import _3 from '../../../../assets/picResources/3.jpg';
export default defineComponent({
    name: "LockScreen",
    setup(){
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils;
        let store = proxy.$store;

        let dataObj:ILockScreenDataObj=reactive<ILockScreenDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            userInfo:JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userInfo") as string)) as IStoreUserInfo,
            backMusic: music1,
            picDialogVisible:false,
            imageSource:{
                selImageName:'',
                selImagePath:'',
                arrayPath: [],
            },
            backgroundImage:'url('+_3+')',
            password:'',
            marginTop:200,
            time:'',
            defaultPic:_3
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new LockScreenUtil(proxy,dataObj);
        })
        onMounted(async()=>{
            await nextTick(()=>{
                dataObj.utilInst.configPoiAndShowTime();
                dataObj.refMap.get('pwdRef').focus();
                dataObj.utilInst.configBgAndInitImageSources();
            })
        })


        //---------------------------computed------------
        const nowTime=computed(()=>{
            return dataObj.time;
        })

        const selImage=computed(()=>{
            return (params:any) => {
                return {
                    imageSelClass:dataObj.imageSource.selImagePath==params.path
                }
            }
        })
        return{
            ...toRefs(dataObj),nowTime,selImage
        }
    }
});